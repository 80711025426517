<template>
  <div id="homepage">
    <!-- ======= Header ======= -->
    <header
      id="header"
      class="fixed-top d-flex align-items-center header-transparent"
    >
      <site-header />
    </header>
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <Carousel
      :titlePage="'Danh sách tin tức' "
    />
    <!-- End Hero -->

    <main id="main">
      <section
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="500"
        class="team news-home-page"
      >
        <div class="container">
          <div class="d-flex">
            <feather-icon
              icon="HomeIcon"
              size="20"
              color="#0282CD"
            />
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
              class="ml-1"
            />
            <div class="titlePage">
              Tin tức
            </div>
          </div>
          <b-row class="mt-2 reverse-element">
            <b-col
              xl="8"
              lg="8"
              md="8"
              sm="12"
            >
              <a
                v-for="item in dataNewsList"
                :key="item.id"
                @click="gotoDetail(item.id)"
              >
                <b-card
                  :img-src="item.avatar ? ($serverfile + item.avatar) : imgDefault"
                  :img-alt="item.name"
                  img-left
                  class="mb-3 cover-img"
                >
                  <b-card-text>
                    <h4 class="item-title-new">{{ item.name }}</h4>
                    <p class="text-truncate">
                      {{ item.groupNew }}
                    </p>
                  </b-card-text>
                  <b-list-group flush>
                    <div>
                      <feather-icon
                        icon="UserIcon"
                        size="18"
                        class="mr-1"
                      />
                      {{ item.createdby }}
                    </div>
                    <div class="mt-1">
                      <feather-icon
                        icon="CalendarIcon"
                        size="18"
                        class="mr-1"
                      />
                      {{ item.createdDate | formatDate }}
                    </div>
                  </b-list-group>
                </b-card>
              </a>
            </b-col>

            <b-col
              xl="4"
              lg="4"
              md="4"
              sm="12"
            >
              <Search
                class="mb-2"
                @change="search($event)"
              />

              <div class="table-tree-news p-2">
                <h4>
                  Chủ đề
                </h4>
                <b-form-checkbox-group
                  v-model="selectedTopicIds"
                  class="checkbox-new"
                  :options="treeData"
                  value-field="id"
                  text-field="name"
                  stacked
                  @change="() => {
                    urlQuery.topicId = JSON.stringify(selectedTopicIds)
                    fetchNewsData(urlQuery)
                  }"
                >
                </b-form-checkbox-group>
                <b-button
                  class="button-tree mt-2  animate__animated animate__fadeInDown"
                  variant="primary"
                  @click="resetFilter"
                >
                  Xóa tất cả bộ lọc
                </b-button>
              </div>
            </b-col>
          </b-row>
          <my-pagination
            class="pagination-new"
            :totalItems="totalRecord"
            :currentPage="urlQuery.pageNumber"
            :showPageSelect="false"
            @pageClick="pageChange"
          />
        </div>
      </section>

      <!-- pagination -->
    </main>

    <!-- ======= Footer ======= -->
    <footer
      id="footer"
      data-aos="fade-up"
      data-aos-easing="ease-in-out"
      data-aos-duration="500"
    >
      <site-footer />
    </footer>
    <!-- End Footer -->

  </div>
</template>
<script src="./assets/js/main.js"></script>
<script>
import "./assets/vendor/aos/aos.js";
import SiteHeader from './components/SiteHeader.vue'
import SiteFooter from './components/SiteFooter.vue'
import Search from '@/components/search/Search.vue'
import LiquiTree from 'liquor-tree'
import MyPagination from '@/components/pagination/MyPagination.vue'
import Carousel from './components/Carousel.vue'
import ConstantsApi from './constants/ConstantsApi'
import store from './store'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import {
  BRow,
  BCol,
  BFormCheckboxGroup,
  BFormCheckbox,
  BCard,
  BCardBody,
  BCardText,
  BCardHeader,
  BListGroup,
  BInputGroup,
  BPagination,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BCol,
    BFormCheckboxGroup,
    BFormCheckbox,
    BCard,
    BCardBody,
    BCardText,
    BListGroup,
    BCardHeader,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BPagination,
    SiteHeader,
    SiteFooter,
    Carousel,
    MyPagination,
    Search,
    LiquiTree,
    BButton,
    BRow,
  },
  data() {
    return {
      tokenCheck: localStorage.getItem("token"),
      urlQuery: {
        topicId: '',
        key: '',
        pageSize: 5,
        pageNumber: 1,
      },
      totalRecord: 0,
      dataNewsList: [],
      options: {
        checkbox: true,
        propertyNames: {
          value: 'id',
          text: 'name',
        },
      },
      treeData: [
      ],
      selectedTopicIds: [],
      imgDefault: store.imgDefault,
    };
  },
  mounted() {
    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      }
      return document.querySelector(el);
    };

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      const selectEl = select(el, all);
      if (selectEl) {
        if (all) {
          selectEl.forEach((e) => e.addEventListener(type, listener));
        } else {
          selectEl.addEventListener(type, listener);
        }
      }
    };

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
      el.addEventListener("scroll", listener);
    };

    /**
     * Scrolls to an element with header offset
     */
    const scrollto = (el) => {
      const header = select("#header");
      let offset = header.offsetHeight;

      if (!header.classList.contains("header-scrolled")) {
        offset -= 20;
      }

      const elementPos = select(el).offsetTop;
      window.scrollTo({
        top: elementPos - offset,
        behavior: "smooth",
      });
    };

    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */
    const selectHeader = select("#header");
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add("header-scrolled");
        } else {
          selectHeader.classList.remove("header-scrolled");
        }
      };
      window.addEventListener("load", headerScrolled);
      onscroll(document, headerScrolled);
    }

    /**
     * Back to top button
     */
    const backtotop = select(".back-to-top");
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add("active");
        } else {
          backtotop.classList.remove("active");
        }
      };
      window.addEventListener("load", toggleBacktotop);
      onscroll(document, toggleBacktotop);
    }

    /**
     * Mobile nav toggle
     */
    // eslint-disable-next-line func-names
    on("click", ".mobile-nav-toggle", function (e) {
      select("#navbar").classList.toggle("navbar-mobile");
      this.classList.toggle("bi-list");
      this.classList.toggle("bi-x");
    });

    /**
     * Mobile nav dropdowns activate
     */
    // eslint-disable-next-line func-names
    on(
      "click",
      ".navbar .dropdown > a",
      function (e) {
        if (select("#navbar").classList.contains("navbar-mobile")) {
          e.preventDefault();
          this.nextElementSibling.classList.toggle("dropdown-active");
        }
      },
      true
    );

    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    // eslint-disable-next-line func-names
    on(
      "click",
      ".scrollto",
      function (e) {
        if (select(this.hash)) {
          e.preventDefault();

          const navbar = select("#navbar");
          if (navbar.classList.contains("navbar-mobile")) {
            navbar.classList.remove("navbar-mobile");
            const navbarToggle = select(".mobile-nav-toggle");
            navbarToggle.classList.toggle("bi-list");
            navbarToggle.classList.toggle("bi-x");
          }
          scrollto(this.hash);
        }
      },
      true
    );

    /**
     * Skills animation
     */
    const skilsContent = select(".skills-content");
    if (skilsContent) {
      // eslint-disable-next-line no-new
      new Waypoint({
        element: skilsContent,
        offset: "80%",
        handler(direction) {
          const progress = select(".progress .progress-bar", true);
          progress.forEach((el) => {
            el.style.width = `${el.getAttribute("aria-valuenow")}%`;
          });
        },
      });
    }

    /**
     * Testimonials slider
     */
    // eslint-disable-next-line no-new
    new Swiper(".testimonials-carousel", {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    });

    /**
     * Porfolio isotope and filter
     */
    window.addEventListener("load", () => {
      const portfolioContainer = select(".portfolio-container");
      if (portfolioContainer) {
        // eslint-disable-next-line no-undef
        const portfolioIsotope = new Isotope(portfolioContainer, {
          itemSelector: ".portfolio-wrap",
          layoutMode: "fittotalRecord",
        });

        const portfolioFilters = select("#portfolio-flters li", true);

        // eslint-disable-next-line func-names
        on(
          "click",
          "#portfolio-flters li",
          function (e) {
            e.preventDefault();
            portfolioFilters.forEach((el) => {
              el.classList.remove("filter-active");
            });
            this.classList.add("filter-active");

            portfolioIsotope.arrange({
              filter: this.getAttribute("data-filter"),
            });
            portfolioIsotope.on("arrangeComplete", () => {
              // eslint-disable-next-line no-undef
              AOS.refresh();
            });
          },
          true
        );
      }
    });

    /**
     * Initiate portfolio lightbox
     */
    // eslint-disable-next-line no-undef
    const portfolioLightbox = GLightbox({
      selector: ".portfolio-lightbox",
    });

    /**
     * Portfolio details slider
     */
    // eslint-disable-next-line no-new
    // eslint-disable-next-line no-undef
    new Swiper(".portfolio-details-slider", {
      speed: 400,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    });

    /**
     * Animation on scroll
     */
    window.addEventListener("load", () => {
      // eslint-disable-next-line no-undef
      AOS.init({
        duration: 1000,
        easing: "ease-in-out",
        once: true,
        mirror: false,
      });
    });
  },
  created() {
    if (this.$route.params.check === true) {
      this.reload();
    }
    // this.fetchNewsData(this.urlQuery)
    this.fetch()
    this.fetchTree()
  },
  methods: {
    async fetchNewsData(urlQuery) {
      const { data } = await axiosApiInstance.get(ConstantsApi.NEWS_GET_PAGING, {
        params: urlQuery,
      })
      this.dataNewsList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
    },
    reload() {
      setTimeout(this.$router.go(), 4000);
    },

    async fetch(){
    await this.fetchNewsData(this.urlQuery)

    },

    async fetchTree(){
      const { data }  = await axiosApiInstance.get(ConstantsApi.COMBOBOX_GROUP_NEWS)
        this.treeData = data?.data
    },

    gotoDetail(id) {
      this.$router.push({ name: "news-detail-home",params: {id} });
    },

    resetFilter(){
      this.urlQuery.topicId = ''
      this.selectedTopicIds = []
      this.fetchNewsData(this.urlQuery)
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchNewsData(this.urlQuery)
    },

    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchNewsData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchNewsData(this.urlQuery)
      }
    },
  },
};
</script>
<style lang="scss">
#main {
  .container {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    margin-right: auto;
    margin-left: auto;
  }
}

.item-title-new {
  width: 700px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-tree-news{
  background: #ffffff;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);

  .custom-checkbox{
    margin: 8px;
  }
  .button-tree{
    width: 100%;
  }
}

.pagination-new{
  width: 70%;
  display: flex;
  justify-content: center;
}
.news-home-page {
  .cover-img {
    img {
      height: 168px;
      width: 168px;
      object-fit: cover;
    }
  }
}

@media (min-width: 770px) and (max-width: 1024px) {
  .item-title-new {
    width: 425px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .item-title-new {
    width: 270px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .news-home-page {
    .cover-img {
      img {
        height: 180px;
        width: 180px;
        object-fit: cover;
      }
    }
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .item-title-new {
    width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .news-home-page {
    .cover-img {
      img {
        height: 100px;
        width: 100px;
        object-fit: cover;
        padding: 10px 0px 0px 10px;
      }
    }
  }

  .reverse-element {
    flex-wrap: wrap-reverse !important;
  }

  .table-tree-news {
    margin-bottom: 2rem;
  }

  .pagination-new{
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 375px) {
  .item-title-new {
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .news-home-page {
    .cover-img {
      img {
        height: 100px;
        width: 100px;
        object-fit: cover;
        padding: 10px 0px 0px 10px;
      }
    }
  }

  .reverse-element {
    flex-wrap: wrap-reverse !important;
  }

  .table-tree-news {
    margin-bottom: 2rem;
  }

  .pagination-new{
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
